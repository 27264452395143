import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Ads module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'preview',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'preview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'duration',
    label: 'Duration',
    width: '10%',
    align: 'right',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'preRoll',
    label: 'Pre-Roll',
    width: '10%',
    align: 'center',
    dataKey: 'preRoll',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'midRoll',
    label: 'Mid-Roll',
    width: '10%',
    align: 'center',
    dataKey: 'midRoll',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'status',
    label: 'Status',
    width: '15%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },

  {
    id: 'createdAt',
    label: 'Created At',
    width: '20%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_ASSETS.value,
    label: ACTIONS.UPLOAD_ASSETS.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'preRoll',
    label: 'Pre-Roll',
    dataKey: 'preRoll',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'midRoll',
    label: 'Mid-Roll',
    dataKey: 'midRoll',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    dataKey: 'cdnStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified At',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
