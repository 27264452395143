import React, { useState, useEffect } from 'react';
import {
  Stack,
  Button,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Status from '../form-elements/status.component';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewData,
  createNewData,
  updateDataById,
} from '../../services/smtp-settings.service';
import Loader from '../common/loader.component';

const ListSMTPSettings = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      host: '',
      port: 25,
      encryption: 'None',
      username: '',
      password: '',
      senderEmail: '',
      senderName: '',
      status: 1,
      notes: '',
    },
  });

  const [loader, setLoader] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const saveNewData = (payload) => {
    createNewData(payload)
      .then((res) => {
        setDataId(res.data.id);
        setLoader(false);
        handleActionSuccess('New email setting created successfully.');
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        setLoader(false);
        handleActionSuccess('Email setting updated successfully.');
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setLoader(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };
  useEffect(() => {
    viewData()
      .then((res) => {
        if (res?.data?.id) {
          setDataId(res.data.id);
          // Fill form values
          setValue('name', res.data?.name || '');
          setValue('host', res.data?.host || '');
          setValue('port', Number(res.data?.port || 25));
          setValue('encryption', res.data?.encryption || 'None');
          setValue('username', res.data?.username || '');
          setValue('password', res.data?.password || '');
          setValue('senderEmail', res.data?.senderEmail || '');
          setValue('senderName', res.data?.senderName || '');
          setValue('status', res.data?.status || 0);
          setValue('notes', res.data?.notes || '');
        }
        setLoader(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoader(false);
      });
  }, [dataId]);

  return (
    <>
      {loader && <Loader />}

      {!loader && (
        <>
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name *"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="host"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Host *"
                      type="text"
                      error={!!errors.host}
                      helperText={errors?.host?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="port"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Port *"
                      type="number"
                      error={!!errors.port}
                      helperText={errors?.port?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="encryption"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.encryption?.message}
                      variant="filled"
                    >
                      <InputLabel id="field-status-select-label">
                        Encryption *
                      </InputLabel>
                      <Select
                        id="smtp_status"
                        name="smtp_status"
                        label="Encryption *"
                        value={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="SSL">SSL</MenuItem>
                        <MenuItem value="TLS">TLS</MenuItem>
                      </Select>
                      {errors?.encryption?.message && (
                        <FormHelperText>
                          {errors.encryption.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="username"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username *"
                      type="text"
                      error={!!errors.username}
                      helperText={errors?.username?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="password"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password *"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors?.password?.message || null}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              title={
                                !showPassword
                                  ? 'Show password'
                                  : 'Hide password'
                              }
                            >
                              <Iconify
                                icon={
                                  !showPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="senderEmail"
                  control={control}
                  rules={validationRules.REQUIRED_EMAIL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sender Email *"
                      type="text"
                      error={!!errors.senderEmail}
                      helperText={errors?.senderEmail?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="senderName"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sender Name *"
                      type="text"
                      error={!!errors.senderName}
                      helperText={errors?.senderName?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="subscriber_status"
                      name="subscriber_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1, 2]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} />

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={{ ...maxLengthValidation(200) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 1, py: 2 }}
          >
            <div>
              <Button
                type="submit"
                form="add-edit-form"
                color="primary"
                variant="contained"
                disabled={loader || formSubmitted}
                sx={{ marginRight: 2, marginTop: 2 }}
              >
                Save
              </Button>
            </div>
            <div>
              <Typography variant="body2" color="secondary">
                * indicates a required field
              </Typography>
            </div>
          </Stack>
        </>
      )}
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListSMTPSettings;
