import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import AddEditVideo from '../../components/videos/add-edit-video.component';

const ContentVideoAddEdit = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Videos';

  return (
    <AppLayout pageTitle={pageTitle}>
      <AddEditVideo dataId={id || 0} />
    </AppLayout>
  );
};

export default ContentVideoAddEdit;
