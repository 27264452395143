import React, { useState, useEffect } from 'react';
import { Card, Stack, Button } from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/sku.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import AddEditSKU from './add-edit-sku.component';
import ViewSKU from './view-sku.component';
import { getListData } from '../../services/sku-settings.service';

const ListSKUSettings = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (options.loading) return;
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
        </div>
        <div>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditSKU
          title="Create New SKU"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditSKU
          title={`Edit SKU Details - ${doAction.data.code}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSKU
          title={`SKU Details - ${doAction.data.code}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListSKUSettings;
