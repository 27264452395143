import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import AuthLayout from '../../layout/auth.layout';
import useResponsive from '../../hooks/responsive.hook';
import Logo from '../../components/common/logo.component';
import Login from '../../components/auth/login.component';

const ImageBackground = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: '#050514',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: theme.spacing(3),
}));

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const StyledSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 480,
}));

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 2),
  borderRadius: theme.spacing(1),
  margin: 10,
  marginTop: 30,
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const LoginView = () => {
  const pageTitle = 'Login';
  const mdUp = useResponsive('up', 'md');
  const mdDown = useResponsive('down', 'md');

  return (
    <AuthLayout pageTitle={pageTitle}>
      <ImageBackground>
        <StyledRoot>
          {mdUp && (
            <StyledSection>
              <Logo
                sx={{
                  width: 200,
                  margin: '20px auto',
                  cursor: 'default',
                }}
              />
            </StyledSection>
          )}

          <Container maxWidth="sm">
            <StyledContent>
              {mdDown && (
                <Logo
                  sx={{
                    width: 200,
                    margin: '20px auto',
                    cursor: 'default',
                  }}
                />
              )}

              <Typography variant="h4" align="center">
                LOGIN
              </Typography>

              <Login />
            </StyledContent>
          </Container>
        </StyledRoot>
      </ImageBackground>
    </AuthLayout>
  );
};

export default LoginView;
