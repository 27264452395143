import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListAds from '../../components/ads/list-ads.component';

const ContentAdsView = () => {
  const pageTitle = 'Content / Ads';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListAds />
    </AppLayout>
  );
};

export default ContentAdsView;
