import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import Iconify from '../common/iconify.component';
import { formatNumber } from '../../utils/numbers.util';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const CountWidget = ({ title, total, icon, color, sx }) => (
  <Card
    sx={{
      py: 5,
      textAlign: 'center',
      ...sx,
    }}
  >
    <StyledIcon
      sx={{
        color: (theme) => theme.palette[color].main,
        backgroundImage: (theme) =>
          `linear-gradient(135deg, ${alpha(
            theme.palette[color].dark,
            0
          )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </StyledIcon>

    <Typography variant="h3">{formatNumber(total || 0)}</Typography>

    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      {title}
    </Typography>
  </Card>
);

CountWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};
CountWidget.defaultProps = {
  color: 'primary',
  sx: {},
};

export default CountWidget;
