import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/smtp/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewData = async () => {
  const options = prepareRequestOptions(`/admin/smtp/view`, 'GET', null, true);

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/smtp/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
