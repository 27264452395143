import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import VideoChoices from '../../components/videos/video-choice.component';

const ContentVideoChoices = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Videos / Choices';

  return (
    <AppLayout pageTitle={pageTitle}>
      <VideoChoices dataId={id || 0} />
    </AppLayout>
  );
};

export default ContentVideoChoices;