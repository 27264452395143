import React, { useState, useEffect } from 'react';
import { Card, Stack, Button, Drawer } from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/subscribers.config';
import { formatDate } from '../../utils/datetime.util';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewSubscriber from './view-subscriber.component';
import AddEditSubscriber from './add-edit-subscriber.component';
import ChangePassword from './change-password.component';
import FilterSubscribers from './filter-subscribers.component';
import {
  getListData,
  deleteDataById,
} from '../../services/subscribers.service';

const ListSubscribers = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: '',
      startDate: null,
      endDate: null,
      platforms: [],
      status: null,
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [openFilters, setOpenFilters] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (options.loading) return;
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess('User deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };
  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search.trim()) counter += 1;
    if (selectedFilters.startDate) counter += 1;
    if (selectedFilters.endDate) counter += 1;
    if (selectedFilters.platforms.length > 0) counter += 1;
    if (selectedFilters.status !== null) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };
  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        search: '',
        startDate: null,
        endDate: null,
        platforms: [],
        status: null,
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = options.appliedFilters;
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }
    if (filters.startDate) {
      const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
      params.push(`startDate=${encodeURIComponent(startDate)}`);
    }
    if (filters.endDate) {
      const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
      params.push(`endDate=${encodeURIComponent(endDate)}`);
    }
    if (filters.platforms.length > 0) {
      const platforms = [];
      filters.platforms.forEach((p) => platforms.push(p.id));
      params.push(`platforms=${platforms.join(',')}`);
    }
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
        </div>
        <div>
          <Button
            color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
            variant="contained"
            startIcon={<Iconify icon="ic:round-filter-list" />}
            sx={{ marginRight: 2 }}
            onClick={handleToggleFilters}
          >
            Filters
            {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
          </Button>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilters}
        onClose={handleToggleFilters}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterSubscribers
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditSubscriber
          title="Create New User"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditSubscriber
          title={`Edit User Details - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSubscriber
          title={`User Details - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete User"
          message={`Do you want to delete ${doAction.data.email} user? You can not undo this action!`}
          noteText={
            doAction.data.subscriptionStatus
              ? 'Deleting the user will not delete/cancel the subscriptions assigned to the user. Please notify the user to delete/cancel subscriptions prior account deletion.'
              : ''
          }
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.CHANGE_PASSWORD.value && (
        <ChangePassword
          title={`Change Password For User - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListSubscribers;
