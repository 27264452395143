import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListVideos from '../../components/videos/list-videos.component';

const ContentVideosView = () => {
  const pageTitle = 'Content / Videos';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListVideos />
    </AppLayout>
  );
};

export default ContentVideosView;
