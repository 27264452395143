import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import Iconify from './iconify.component';
import SnackbarInfo from './snackbar-info.component';
import ImageUpload from './image-upload.component';
import VideoUpload from './video-upload.component';
import { getPlayableStream } from '../../services/videos.service';
import { VIDEO_TYPE } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UploadAssets = ({
  title,
  dataId,
  dataTitle,
  onCancel,
  contentType,
  assetDetail,
}) => {
  const [assetData, setAssetData] = useState({
    videoUrl: '',
    trailerUrl: '',
    landscapeImage: '',
    portraitImage: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [tokenizeAssetData, setTokenizeAssetData] = useState({
    tokenizeVideoUrl: '',
    tokenizeTrailerUrl: '',
  });

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const assetDataCallback = (imageUrl, type) => {
    if (type === 'Landscape') {
      setAssetData({
        ...assetData,
        landscapeImage: imageUrl || '',
      });
    } else {
      setAssetData({
        ...assetData,
        portraitImage: imageUrl || '',
      });
    }
  };

  useEffect(() => {
    setAssetData({
      ...assetData,
      videoUrl: assetDetail?.videoUrl || '',
      trailerUrl: assetDetail?.trailerUrl || '',
      landscapeImage: assetDetail?.landscapeImage || '',
      portraitImage: assetDetail?.portraitImage || '',
    });

    if (assetDetail?.trailerUrl || assetDetail?.videoUrl) {
      let uri = `videoId=${dataId}`;
      uri += `&contentType=${contentType}`;
      uri += `&upload=true`;

      getPlayableStream(uri)
        .then((res) => {
          setTokenizeAssetData({
            ...tokenizeAssetData,
            tokenizeVideoUrl: res.data?.videoUrl,
            tokenizeTrailerUrl: res.data?.trailerUrl,
          });
        })
        .catch(() =>
          setTimeout(() => {
            setTokenizeAssetData({
              ...tokenizeAssetData,
              tokenizeVideoUrl: '',
              tokenizeTrailerUrl: '',
            });
          }, 5000)
        );
    }
  }, []);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{`${
        contentType === 'videos' ? 'Videos' : 'Ads'
      } - ${title}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form id="upload-assets-form">
          {contentType === 'videos' && (
            <>
              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '2px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                }}
              >
                <ImageUpload
                  title="Landscape"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  assetData={assetData}
                  assetDataCallback={assetDataCallback}
                />
              </Box>
              <Box
                component="section"
                sx={{
                  p: 3,
                  border: '2px solid lightgrey',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: 3,
                }}
              >
                <ImageUpload
                  title="Portrait"
                  dataId={dataId}
                  showToastMsg={showToastMsg}
                  setSnackbarInfo={setSnackbarInfo}
                  assetData={assetData}
                  assetDataCallback={assetDataCallback}
                />
              </Box>
              {assetDetail?.videoType === VIDEO_TYPE[1] && (
                <Box
                  component="section"
                  sx={{
                    p: 3,
                    border: '2px solid lightgrey',
                    borderRadius: '5px',
                    width: '100%',
                    marginTop: contentType === 'videos' && 3,
                  }}
                >
                  <VideoUpload
                    title="Trailer"
                    dataId={dataId}
                    dataTitle={dataTitle}
                    showToastMsg={showToastMsg}
                    assetData={assetData}
                    setSnackbarInfo={setSnackbarInfo}
                    contentType={contentType}
                    tokenizeUrl={tokenizeAssetData?.tokenizeTrailerUrl || ''}
                  />
                </Box>
              )}
            </>
          )}
          <Box
            component="section"
            sx={{
              p: 3,
              border: '2px solid lightgrey',
              borderRadius: '5px',
              width: '100%',
              marginTop: contentType === 'videos' && 3,
            }}
          >
            <VideoUpload
              title="Video"
              dataId={dataId}
              dataTitle={dataTitle}
              showToastMsg={showToastMsg}
              assetData={assetData}
              setSnackbarInfo={setSnackbarInfo}
              contentType={contentType}
              tokenizeUrl={tokenizeAssetData?.tokenizeVideoUrl || ''}
            />
          </Box>
        </form>
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

UploadAssets.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  assetDetail: PropTypes.object.isRequired,
};

export default UploadAssets;
