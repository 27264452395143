import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import Iconify from './iconify.component';
import Player from './player.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const VideoPreview = ({ videoPlayer, setVideoPlayer }) => (
  <Dialog
    fullScreen
    open={videoPlayer.show}
    onClose={() => setVideoPlayer({ ...videoPlayer, show: false })}
    TransitionComponent={Transition}
  >
    <AppBar
      color="transparent"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        size="medium"
        onClick={() => setVideoPlayer({ ...videoPlayer, show: false })}
        aria-label="close"
        sx={{ width: 'fit-content', marginLeft: '0' }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        {`You are watching - ${videoPlayer.title} ${videoPlayer.previewType}`}
      </Typography>
    </AppBar>

    <DialogContent
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Player
        videoUrl={
          videoPlayer.previewType === 'video' ||
          videoPlayer.previewType === 'ad'
            ? videoPlayer.videoUrl
            : videoPlayer.trailerUrl
        }
        height="810px"
        width="1440px"
        autoplay
      />
    </DialogContent>
  </Dialog>
);

VideoPreview.propTypes = {
  videoPlayer: PropTypes.object.isRequired,
  setVideoPlayer: PropTypes.func.isRequired,
};

export default VideoPreview;
