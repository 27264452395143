import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Stack,
  Button,
  Container,
  Paper,
  CardContent,
  Box,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useNavigate } from 'react-router-dom';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  getListSequence,
  updateCategoryVideosSequence,
} from '../../services/categories.service';
import ROUTE_PATH from '../../config/routes.config';
import editUrl from '../../utils/url-edit.util';

const ManageVideoOrder = ({ id }) => {
  const navigate = useNavigate();
  const [vidOptions, setVidOptions] = useState({
    error: false,
    loading: true,
  });
  const [action, setAction] = useState({
    show: false,
    data: '',
  });
  const [categoryVideosList, setCategoryVideosList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleUpdateVideosSequence = () => {
    setDisable(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const updatedVideosCategorySequence = categoryVideosList.map(
      (video) => video.video_id
    );

    const payload = {
      categoryId: id,
      newSequence: updatedVideosCategorySequence,
    };

    updateCategoryVideosSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `video sequence updated successfully.`,
          });
          setDisable(false);
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };
  const handleOnDragEndVideos = (result) => {
    if (result.destination) {
      const newVideos = Array.from(categoryVideosList);
      const [draggedItem] = newVideos.splice(result.source.index, 1);

      newVideos.splice(result.destination.index, 0, draggedItem);
      setCategoryVideosList(newVideos);
    }
  };

  const handleBack = () => {
    const url = editUrl(ROUTE_PATH.CONTENT_CATEGORIES_MANAGE_VIDEOS, id);
    navigate(url);
  };

  useEffect(() => {
    setAction({ ...action, show: true, data: id });

    let uri = 'video-category';
    uri += `?categoryId=${id}`;

    getListSequence(uri)
      .then((res) => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: false,
        });
        setCategoryVideosList(res.data);
      })
      .catch(() =>
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: true,
        })
      );
  }, [id]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 3, py: 1 }}
      >
        <Box fontWeight="bold" fontSize="20px">
          Manage Video Order
        </Box>

        <Box>
          <Button
            color="primary"
            variant="contained"
            disabled={disable}
            sx={{ marginRight: 2 }}
            onClick={() => handleUpdateVideosSequence()}
          >
            Save Order
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleBack()}
          >
            Back
          </Button>
        </Box>
      </Stack>

      <Container
        maxWidth="false"
        sx={{ maxHeight: '74vh', overflowY: 'auto', paddingX: 0, paddingY: 1 }}
      >
        <DragDropContext onDragEnd={handleOnDragEndVideos}>
          <Droppable droppableId="videos" isDropDisabled={false}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {!vidOptions.loading &&
                  categoryVideosList.length > 0 &&
                  categoryVideosList.map((video, index) => (
                    <Draggable
                      key={`video-${video.id}`}
                      draggableId={video.id.toString()}
                      index={index}
                    >
                      {(provide) => (
                        <Card
                          sx={{
                            marginBottom: '5px',
                          }}
                          ref={provide.innerRef}
                          {...provide.draggableProps}
                          {...provide.dragHandleProps}
                        >
                          <CardContent
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingY: '12px',
                              '&:last-child': {
                                paddingBottom: '12px',
                              },
                            }}
                          >
                            <div>{video.title}</div>
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                  ))}

                {!vidOptions.loading && categoryVideosList.length === 0 && (
                  <Paper
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '10vh',
                    }}
                  >
                    No data available.
                  </Paper>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ManageVideoOrder.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ManageVideoOrder;
