import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@mui/material';
import { PRIMARY } from '../../theme/theme-colors';
import Iconify from '../common/iconify.component';

const TreeNodeLabel = ({ onNodeClick, nodeData }) => (
  <div
    style={{
      position: 'relative',
    }}
  >
    <div
      style={{
        background: '#ffffff',
        height: '100px',
        margin: '10px',
        borderTop: `2px solid ${PRIMARY.main}`,
        textAlign: 'center',
        position: 'relative',
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
        padding: '5px 10px',
        borderRadius: '5px',
      }}
    >
      <Typography
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          height: '50px',
        }}
        title={nodeData.title}
      >
        {nodeData.title}
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            onNodeClick(nodeData, 'add');
          }}
          size="big"
          sx={{
            color: 'green',
          }}
        >
          <Iconify icon="gala:add" />
        </IconButton>
        {nodeData.hierarchy_id && (
          <IconButton
            onClick={() => {
              onNodeClick(nodeData, 'delete');
            }}
            size="big"
            sx={{
              color: 'red',
            }}
          >
            <Iconify icon="ant-design:delete-outlined" />
          </IconButton>
        )}
      </div>
    </div>
  </div>
);

TreeNodeLabel.propTypes = {
  onNodeClick: PropTypes.func.isRequired,
  nodeData: PropTypes.func.isRequired,
};

export default TreeNodeLabel;
