import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, VIDEO_TYPE } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapeImage',
    label: 'Landscape Image',
    width: '5%',
    align: 'left',
    dataKey: 'landscapeImage',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'portraitImage',
    label: 'Portrait Image',
    width: '5%',
    align: 'left',
    dataKey: 'portraitImage',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'videoType',
    label: 'Type',
    align: 'left',
    dataKey: 'videoType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'mainVideoTitle',
    label: 'Main Video',
    align: 'left',
    dataKey: 'mainVideoTitle',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'startDate',
    label: 'Video Start Date',
    align: 'left',
    dataKey: 'startDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'endDate',
    label: 'Video End Date',
    align: 'left',
    dataKey: 'endDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'preview',
    label: 'Video',
    align: 'center',
    dataKey: 'preview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'duration',
    label: 'Duration',
    align: 'center',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'trailerPreview',
    label: 'Trailer',
    align: 'center',
    dataKey: 'trailerPreview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_ASSETS.value,
    label: ACTIONS.UPLOAD_ASSETS.label,
  },
  {
    action: ACTIONS.GEO_RESTRICTION.value,
    label: ACTIONS.GEO_RESTRICTION.label,
    conditional: (val) => val.videoType === VIDEO_TYPE[1],
  },
  {
    action: ACTIONS.SHOWCHOICE.value,
    label: ACTIONS.SHOWCHOICE.label,
    conditional: (val) => val.videoType === VIDEO_TYPE[1],
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Name',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'shortDescription',
    label: 'Short Description',
    dataKey: 'shortDescription',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'mainVideoTitle',
    label: 'Main Video Title',
    dataKey: 'mainVideoTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'avod',
    label: 'AVOD',
    dataKey: 'avod',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'tvod',
    label: 'TVOD',
    dataKey: 'tvod',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'earlyAccess',
    label: 'Early Access',
    dataKey: 'earlyAccess',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'videoUrl',
    label: 'Video Url',
    dataKey: 'videoUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'showDuration',
    label: 'Show Duration',
    dataKey: 'showDuration',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'landscapeImage',
    label: 'Landscape Image',
    dataKey: 'landscapeImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapeThumb',
    label: 'Landscape Thumb Image',
    dataKey: 'landscapeThumb',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'portraitImage',
    label: 'Portrait Image',
    dataKey: 'portraitImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'portraitThumb',
    label: 'Portrait Thumb Image',
    dataKey: 'portraitThumb',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'trailerUrl',
    label: 'Trailer Url',
    dataKey: 'trailerUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'earlyAccessStart',
    label: 'Early Access Start Date',
    dataKey: 'earlyAccessStart',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'start',
    label: 'Start Date',
    dataKey: 'start',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'end',
    label: 'End Date',
    dataKey: 'end',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'year',
    label: 'Year',
    dataKey: 'year',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'rating',
    label: 'Rating',
    dataKey: 'rating',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'cast',
    label: 'Cast',
    dataKey: 'cast',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'director',
    label: 'Director',
    dataKey: 'director',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'genre',
    label: 'Genre',
    dataKey: 'genre',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'writers',
    label: 'Writers',
    dataKey: 'writers',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: 'SKU',
    dataKey: 'sku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'earlyAccessSku',
    label: 'Early Access SKU',
    dataKey: 'earlyAccessSku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'price',
    label: 'Price',
    dataKey: 'price',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'earlyAccessSkuPrice',
    label: 'Early Access Price',
    dataKey: 'earlyAccessSkuPrice',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    dataKey: 'cdnStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified At',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIDEOJS_OPTIONS = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  sources: [
    {
      src: '',
    },
  ],
};
