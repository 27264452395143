// Define platforms list
const PLATFORMS_LIST = [
  {
    id: 'ROKU',
    title: 'Roku',
  },
  {
    id: 'FIRETV',
    title: 'Fire TV',
  },
  {
    id: 'ANDROIDTV',
    title: 'Android TV',
  },
  {
    id: 'ANDROID_MOBILE',
    title: 'Android Mobile',
  },
  {
    id: 'APPLE_MOBILE',
    title: 'Apple Mobile',
  },
  {
    id: 'SAMSUNG',
    title: 'Samsung TV',
  },
  {
    id: 'LG',
    title: 'LG TV',
  },
  {
    id: 'WEB',
    title: 'Web',
  },
  {
    id: 'ADMIN',
    title: 'Admin',
  },
];

export const getPlatformLabel = (val) => {
  let label = '';

  PLATFORMS_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export const getPlatformColorCode = (platform) => {
  if (platform === 'ROKU') return '#6F1AB1';
  if (platform === 'FIRETV') return '#FC4C02';
  if (platform === 'FIRE_MOBILE') return '#FFECD5';
  if (platform === 'ANDROIDTV') return '#9DD67D';
  if (platform === 'ANDROID_MOBILE') return '#5B998A';
  if (platform === 'APPLETV') return '#4CD0F1';
  if (platform === 'APPLE_MOBILE') return '#000000';
  if (platform === 'WEB') return '#3459E0';
  return '#642424';
};

export default PLATFORMS_LIST;
