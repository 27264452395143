import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  updatePasswordById,
} from '../../services/admin-users.service';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ChangePassword = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const passwordFieldValues = watch('password');

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      newPassword: data.password,
    };
    updatePasswordById(dataId, payload)
      .then(() => {
        onSuccess('Password changed successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    viewDataById(dataId)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="change-password-dialog-title"
      aria-describedby="change-password-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="change-password-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="change-password-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="change-password" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.PASSWORD,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="New Password *"
                      type={showPassword.password ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors?.password?.message || null}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  password: !showPassword.password,
                                })
                              }
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword.password
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.PASSWORD,
                    validate: (value) =>
                      value !== passwordFieldValues
                        ? 'Password do not match.'
                        : true,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Re-enter New Password *"
                      type={showPassword.confirmPassword ? 'text' : 'password'}
                      error={!!errors.confirmPassword}
                      helperText={errors?.confirmPassword?.message || null}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  confirmPassword:
                                    !showPassword.confirmPassword,
                                })
                              }
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword.confirmPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="change-password"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Change Password
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ChangePassword.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ChangePassword;
