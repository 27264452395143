import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListPurchases from '../../components/purchases/list-purchases.component';

const BillingPaymentsView = () => {
  const pageTitle = 'User Management / Purchases';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListPurchases />
    </AppLayout>
  );
};

export default BillingPaymentsView;
