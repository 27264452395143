import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import ManageVideoOrder from '../../components/categories/manage-video-order';

const ContentCategoriesVideoOrder = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Categories';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ManageVideoOrder id={id} />
    </AppLayout>
  );
};

export default ContentCategoriesVideoOrder;
