import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// manage videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Video Name',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
