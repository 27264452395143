import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advanceFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);

export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);

export const getDate = (days = 0) => {
  const date = new Date();
  if (days) {
    date.setDate(date.getDate() + days);
  }
  return date;
};

export const formatDate = (inputDate, format = 'YYYY-MM-DD') => {
  if (!inputDate) return '';

  return dayjs(inputDate).format(format);
};

export const formatTimestamp = (inputTimestamp, format = 'YYYY-MM-DD') => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000).format(format);
};

export const formatDateToUtc = (inputDate) => {
  if (!inputDate) return '';

  return dayjs(inputDate).utc().format();
};

export const formatTimestampToUtc = (inputTimestamp) => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000)
    .utc()
    .format();
};

export const convertDateStringToTimestamp = (date) => {
  if (!date) return 0;

  const timestamp = Date.parse(date);
  return Math.floor(timestamp / 1000);
};

export const formatGmtStringToLocale = (
  inputGmtString,
  format = '',
  advance = false
) => {
  if (!inputGmtString) return '';

  if (advance) {
    dayjs.extend(advanceFormat);
  }

  return dayjs(inputGmtString).format(format);
};

const nth = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getFormattedDate = (dt) => {
  const fDate = new Date(dt);
  // Extract the day, month index, and year
  const day = fDate.getDate();
  const monthIndex = fDate.getMonth();
  const year = fDate.getFullYear();

  // Get the day of the week as a number (0-6)
  const dayIndex = fDate.getDay();

  // Map the day index to the weekday name
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const weekday = weekdays[dayIndex];

  // Map the month index to the month name
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = months[monthIndex];

  const today = new Date();

  // Format the complete date string
  let formattedDate = `${weekday}, ${day}${nth(day)} ${month}`;
  if (today.getFullYear() !== year) {
    formattedDate += ` ${year}`;
  }

  if (
    today.getDate() === day &&
    today.getMonth() === monthIndex &&
    today.getFullYear() === year
  ) {
    formattedDate = 'Today';
  }

  return formattedDate;
};
