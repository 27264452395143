import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import Iconify from '../common/iconify.component';
import {
  formatDate,
  formatGmtStringToLocale,
  getFormattedDate,
} from '../../utils/datetime.util';
import Loading from '../table-elements/loading.component';
import ConfirmPopup from '../common/confirm-popup.component';

const TimeLine = ({ date }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ fontSize: '12px' }}
  >
    <Divider width="38%" />
    {date}
    <Divider width="38%" />
  </Stack>
);

TimeLine.propTypes = {
  date: PropTypes.string.isRequired,
};

const CommentsLink = ({ el, getNewComments, loadMoreId }) => {
  const linkId = `${el.direction}_${el.from}_${el.to}`;
  const moreCls = loadMoreId === linkId;
  const buttonDisabled = !!(loadMoreId !== '' && loadMoreId !== linkId);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ fontSize: '10px' }}
    >
      <Button
        onClick={() => {
          getNewComments(el.from, el.to, el.direction);
        }}
        sx={{ display: moreCls ? 'none' : 'flex' }}
        disabled={buttonDisabled}
      >
        Load More
      </Button>

      {moreCls && <Loading minHeight="30px" display="flex" />}
    </Stack>
  );
};

CommentsLink.propTypes = {
  el: PropTypes.object.isRequired,
  getNewComments: PropTypes.func.isRequired,
  loadMoreId: PropTypes.string.isRequired,
};

const commentOptions = [
  { title: 'Active', action: 'active' },
  { title: 'Deactive', action: 'deactive' },
  { title: 'Delete', action: 'delete' },
];

const CommentOptions = ({
  element,
  updateCommentStatus,
  triggerClose,
  setTriggerClose,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const statusRef = useRef(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (triggerClose) {
      handleClose();
      setTriggerClose(false);
    }
  }, [triggerClose]);

  return (
    <>
      <InputAdornment
        position="end"
        sx={{ alignSelf: 'start', marginTop: '10px' }}
        onClick={handleClick}
      >
        <IconButton edge="end" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          <Iconify icon="ph:dots-three-vertical" />
        </IconButton>
      </InputAdornment>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Stack spacing={0.25}>
          {element.status === 1 &&
            commentOptions
              .filter((item) => item.action !== 'active')
              .map((el) => (
                <MenuItem
                  onClick={() => {
                    setDeleteConfirm(true);
                    if (el.action === 'deactive') {
                      statusRef.current = 0;
                    } else {
                      statusRef.current = 2;
                    }
                  }}
                  sx={{ fontSize: '10px' }}
                >
                  {el.title}
                </MenuItem>
              ))}
          {element.status === 0 &&
            commentOptions
              .filter((item) => item.action !== 'deactive')
              .map((el) => (
                <MenuItem
                  onClick={() => {
                    setDeleteConfirm(true);
                    if (el.action === 'active') {
                      statusRef.current = 1;
                    } else {
                      statusRef.current = 2;
                    }
                  }}
                  sx={{ fontSize: '10px' }}
                >
                  {el.title}
                </MenuItem>
              ))}
        </Stack>
      </Menu>

      {deleteConfirm && (
        <ConfirmPopup
          title={`${
            // eslint-disable-next-line no-nested-ternary
            statusRef.current !== 2
              ? statusRef.current === 0
                ? 'Deactive'
                : 'Active'
              : 'Delete'
          } Comment`}
          message={`Do you want to ${
            // eslint-disable-next-line no-nested-ternary
            statusRef.current !== 2
              ? statusRef.current === 0
                ? 'deactive'
                : 'active'
              : 'delete'
          } the comment? ${
            statusRef.current === 2 ? 'You can not undo this action!' : ''
          }`}
          onCancel={() => {
            setDeleteConfirm(false);
            handleClose();
          }}
          onSuccess={() => {
            setDeleteConfirm(false);
            handleClose();
            updateCommentStatus(element.id, statusRef.current);
          }}
        />
      )}
    </>
  );
};

CommentOptions.propTypes = {
  element: PropTypes.object.isRequired,
  updateCommentStatus: PropTypes.func.isRequired,
  triggerClose: PropTypes.bool.isRequired,
  setTriggerClose: PropTypes.func.isRequired,
};

const TextComment = ({
  el,
  updateCommentStatus,
  triggerClose,
  setTriggerClose,
  dateDisplayFlag,
  date,
}) => {
  const theme = useTheme();

  // eslint-disable-next-line no-control-regex
  const isAsciiOnly = (str) => /^[\x00-\x7F]*$/.test(str);
  const fontSize =
    el?.comment?.length === 2 && !isAsciiOnly(el.comment) ? '22px' : '14px';

  return (
    <>
      {dateDisplayFlag && <TimeLine date={date} />}

      <Stack
        direction="row"
        justifyContent={el.isAdmin ? 'end' : 'start'}
        alignItems="start"
      >
        {!el.isAdmin && el.profilePic && (
          <Avatar
            src={el.profilePic}
            alt={`${el.name}`}
            sx={{ width: 26, height: 26, marginTop: '2px' }}
          />
        )}

        {!el.isAdmin && !el.profilePic && (
          <Avatar sx={{ width: 26, height: 26, marginTop: '2px' }}>
            {el.name.charAt(0)}
          </Avatar>
        )}

        <Stack
          direction="row"
          alignItems="start"
          justifyContent={el.isAdmin ? 'end' : 'start'}
          width="max-content"
          maxWidth="70%"
        >
          <Box
            px={1.5}
            sx={{
              borderRadius: 1.5,
              width: 'max-content',
              maxWidth: '85%',
            }}
            flexDirection="column"
          >
            {!el.isAdmin && (
              <Box
                sx={{
                  width: 'max-content',
                  fontSize: '14px',
                  color:
                    el.status === 0 ? '#b7b7b7' : theme.palette.primary.main,
                  fontWeight: '700',
                }}
              >
                {el.name}
              </Box>
            )}
            <Box
              sx={{
                width: 'auto',
                fontSize,
                backgroundColor: el.isAdmin
                  ? theme.palette.primary.lighter
                  : theme.palette.background.default,
                borderRadius: 1.5,
                overflowWrap: 'break-word',
                color: el.status === 0 && '#b7b7b7',
              }}
              py={0.5}
            >
              {el.comment}
            </Box>
          </Box>
          <Box
            sx={{
              width: 'max-content',
              fontSize: '10px',
              textWrap: 'nowrap',
              paddingTop: 0.4,
              color: el.status === 0 && '#b7b7b7',
            }}
          >
            <Tooltip
              title={
                formatGmtStringToLocale(
                  el.createdAt,
                  'ddd, Do MMM YYYY hh:mm:ss A',
                  true
                ) || ''
              }
              arrow
            >
              {formatDate(el.createdAt, 'hh:mm A')}
            </Tooltip>
          </Box>
        </Stack>

        <CommentOptions
          element={el}
          updateCommentStatus={updateCommentStatus}
          triggerClose={triggerClose}
          setTriggerClose={setTriggerClose}
        />
      </Stack>
    </>
  );
};

TextComment.propTypes = {
  el: PropTypes.object.isRequired,
  updateCommentStatus: PropTypes.func.isRequired,
  triggerClose: PropTypes.bool.isRequired,
  setTriggerClose: PropTypes.func.isRequired,
  dateDisplayFlag: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
};

const Comments = ({
  loading,
  comments,
  getNewComments,
  updateCommentStatus,
  triggerClose,
  setTriggerClose,
  loadMoreId,
}) => {
  let displayDate = '';
  let date = '';

  const getDateTime = (dt) => {
    const localDate = new Date(dt).toLocaleString().split(',');

    return { date: localDate[0], time: localDate[1] };
  };

  return (
    <Box p={3}>
      <Stack spacing={2}>
        {loading && <Loading />}

        {!loading && comments.length === 0 && (
          <Typography variant="h6"> No comments available.</Typography>
        )}

        {comments.length > 0 &&
          comments.map((el) => {
            const localDate = getDateTime(el.createdAt);
            let dateDisplayFlag = false;

            if (displayDate !== localDate.date) {
              dateDisplayFlag = true;
              displayDate = localDate.date;
              date = getFormattedDate(el.createdAt);
            }

            switch (el.type) {
              case 'l':
                return (
                  <CommentsLink
                    el={el}
                    getNewComments={getNewComments}
                    loadMoreId={loadMoreId}
                  />
                );

              case 'c':
                return (
                  <TextComment
                    el={el}
                    updateCommentStatus={updateCommentStatus}
                    triggerClose={triggerClose}
                    setTriggerClose={setTriggerClose}
                    dateDisplayFlag={dateDisplayFlag}
                    date={date}
                  />
                );

              default:
                return null;
            }
          })}
      </Stack>
    </Box>
  );
};

Comments.propTypes = {
  loading: PropTypes.bool.isRequired,
  comments: PropTypes.any.isRequired,
  getNewComments: PropTypes.func.isRequired,
  updateCommentStatus: PropTypes.func.isRequired,
  triggerClose: PropTypes.bool.isRequired,
  setTriggerClose: PropTypes.func.isRequired,
  loadMoreId: PropTypes.string.isRequired,
};

export default Comments;
