import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
import { Card, Stack, Button, Grid, Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/manage-videos.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import validationRules from '../../utils/validations.util';
import {
  assignVideos,
  viewVideosById,
  deleteAssignedVideosById,
} from '../../services/categories.service';
import Videos from '../form-elements/videos.component';
import ROUTE_PATH from '../../config/routes.config';
import editUrl from '../../utils/url-edit.util';

const ManageCategoryVideos = ({ id }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      videos: [],
    },
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    filtersCounter: 0,
    categoryName: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (options.loading) return;
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleOpenPage = () => {
    const url = editUrl(ROUTE_PATH.CONTENT_CATEGORIES_MANAGE_VIDEO_ORDER, id);
    navigate(url);
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteAssignedVideosById(id, doAction.data.id)
      .then(() => {
        handleActionSuccess('Assigned video deleted successfully.');
        handleRefreshData();
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const selectedVideos = [];
    for (let i = 0; i < data.videos.length; i += 1) {
      selectedVideos.push(data.videos[i].id);
    }
    const payload = {
      videos: selectedVideos,
    };
    assignVideos(id, payload)
      .then((res) => {
        if (res.data) {
          handleRefreshData();
          handleActionSuccess(' Video assigned successfully.');
          setValue('videos', []);
        }
      })
      .catch((e) => e);

    setFormSubmitted(false);
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    viewVideosById(id, paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          categoryName: res?.data?.categoryName || '',
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          categoryName: '',
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 1, py: 1 }}
      >
        <Box fontWeight="bold" fontSize="20px">{`Manage Videos ${
          options.categoryName ? `- ${options.categoryName}` : ''
        }`}</Box>
        <div>
          <Link to={ROUTE_PATH.CONTENT_CATEGORIES}>
            <Button
              color="primary"
              variant="contained"
              disabled={formSubmitted}
            >
              Back
            </Button>
          </Link>
        </div>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 1, py: 1 }}
      >
        <Box
          width="50%"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box width="90%">
            <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="videos"
                  control={control}
                  rules={validationRules.REQUIRED}
                  defaultValue={[]}
                  render={({ field: { onChange, value } }) => (
                    <Videos
                      id="videos"
                      name="videos"
                      label="Videos"
                      defaultValue={value}
                      multiple
                      onChange={onChange}
                      sx={{ width: '100%', my: 1 }}
                      error={errors?.videos?.message || ''}
                      filterMain
                    />
                  )}
                />
              </Grid>
            </form>
          </Box>

          <Button
            type="submit"
            color="primary"
            form="add-edit-form"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            disabled={formSubmitted}
            sx={{ marginLeft: 2 }}
          >
            Assign
          </Button>
        </Box>

        <Box
          width="40%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            color="primary"
            variant="contained"
            disabled={formSubmitted}
            onClick={() => handleOpenPage()}
            sx={{ marginRight: 2 }}
          >
            Manage Video Order
          </Button>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Assigned Video"
          message="Do you want to delete this record? You can not undo this action!"
          noteText={
            doAction.data.subscriptionStatus
              ? 'Deleting the user will not delete/cancel the subscriptions assigned to the user. Please notify the user to delete/cancel subscriptions prior account deletion.'
              : ''
          }
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ManageCategoryVideos.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ManageCategoryVideos;
