import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListComments from '../../components/comments/list-comments.component';

const ContentCommentsView = () => {
  const pageTitle = 'Content / Comments';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListComments />
    </AppLayout>
  );
};

export default ContentCommentsView;
