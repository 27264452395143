import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSessions from '../../components/reports/list-sessions.component';

const ReportsSessionsView = () => {
  const pageTitle = 'Reports / Views by Session (Video)';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSessions />
    </AppLayout>
  );
};

export default ReportsSessionsView;
