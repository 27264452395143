/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tree from 'react-d3-tree';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Button,
  MenuItem,
  Box,
  TextField,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import TreeNodeLabel from './node-label.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  getChoicesById,
  getChoiceListByParentId,
  assignChoiceToVideo,
  deleteChoiceFromVideo,
} from '../../services/videos.service';
import useCenteredTree from '../../utils/helper.util';
import { PRIMARY } from '../../theme/theme-colors';
import ConfirmPopup from '../common/confirm-popup.component';
import ROUTE_PATH from '../../config/routes.config';

const VideoChoices = ({ dataId }) => {
  const [loading, setLoading] = useState(true);
  const rowData = useRef(null);
  const [choiceChart, setChoiceChart] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const selectedRefData = useRef(null);
  const [translate, containerRef] = useCenteredTree();

  const [newChoices, setNewChoices] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayOrder, setDisplayOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModelOpen, setConfirmModelOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setDisplayOrder(null);
  };
  const handleDeleteClick = () => {
    setConfirmModelOpen(true);
  };
  const handleCloseConfirmOpen = () => {
    setConfirmModelOpen(false);
  };

  const getDataFromParentId = (data, filterId, allData) => {
    const tree = [];

    data.forEach((item) => {
      if (item.hierarchy_id === filterId) {
        const children = getDataFromParentId(data, item.tree_id, allData);
        if (children.length) {
          /* eslint-disable no-param-reassign */
          item.children = children;
        }
        tree.push(item);
        allData.push(item);
      }
    });
    return tree;
  };
  const setupChoiceChart = (data) => {
    const rootData = data.filter((item) => item.hierarchy_id === null);
    const allData = [];
    const orgChart = getDataFromParentId(
      data,
      rootData[0].hierarchy_id,
      allData
    );
    setChoiceChart(orgChart);
  };

  const showChoicesChildNode = (nodeData, action) => {
    selectedRefData.current = nodeData;
    if (action === 'add') {
      const id = nodeData?.hierarchy_id || nodeData.tree_id;
      const params = [];
      params.push(`tree_id=${nodeData.tree_id}`, `root_id=${dataId}`);
      const queryParams = params.length > 0 ? `?${params.join('&')}` : '';
      getChoiceListByParentId(id, queryParams)
        .then((res) => {
          setNewChoices(res.data);
          handleOpen();
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });
    } else if (action === 'delete') {
      handleDeleteClick();
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }
    viewDataById(dataId)
      .then((res) => {
        // setRowData(res.data);
        rowData.current = res.data;
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });

    getChoicesById(dataId)
      .then((res) => {
        setLoading(false);
        setupChoiceChart(res.data);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  const clickToAddChoice = async () => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    if (selectedItem) {
      const event = selectedRefData.current;
      let order = displayOrder;
      if (!order) {
        if (event.children) {
          order =
            event.children?.length > 0
              ? event.children[event.children.length - 1].display_order + 1
              : 1;
        } else {
          order = 1;
        }
      }
      const payload = {
        videoId: event.videoId,
        choiceId: selectedItem.id,
        hierarchyId: event.tree_id,
        displayOrder: order,
        parentId: event.hierarchy_id,
        mainVideoId: dataId,
      };
      assignChoiceToVideo(payload)
        .then((res) => {
          setChoiceChart((prev) => {
            const newChart = JSON.parse(JSON.stringify(prev));

            const findAndAddNode = (nodes, treeId, newNode) => {
              for (const node of nodes) {
                if (node.tree_id === treeId) {
                  if (!node.children) {
                    node.children = [];
                  }
                  node.children.push(newNode);
                  return true;
                }
                if (node.children && node.children.length > 0) {
                  const found = findAndAddNode(node.children, treeId, newNode);
                  if (found) {
                    return true;
                  }
                }
              }
              return false;
            };

            const newNode = {
              title: selectedItem.title,
              videoId: selectedItem.id,
              landscape_thumb: selectedItem.landscape_thumb,
              tree_id: res.data.id,
              parent_id: event.videoId,
              hierarchy_id: event.tree_id,
              display_order: displayOrder,
            };

            findAndAddNode(newChart, event.tree_id, newNode);

            return newChart;
          });
          setSelectedItem(null);
          handleClose();
          setNewChoices([]);
        })
        .catch((error) => {
          const message =
            error.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });
    } else {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Please select choice.',
        });
      }, 100);
    }
  };

  const clickToDeleteChoice = () => {
    const event = selectedRefData.current;
    deleteChoiceFromVideo(event.tree_id)
      .then(() => {
        setChoiceChart((prev) => {
          const newChart = JSON.parse(JSON.stringify(prev));

          const findAndDeleteNode = (nodes, treeId) => {
            for (let i = 0; i < nodes.length; i += 1) {
              const node = nodes[i];
              if (node.tree_id === treeId) {
                nodes.splice(i, 1);
                return true;
              }
              if (node.children && node.children.length > 0) {
                const found = findAndDeleteNode(node.children, treeId);
                if (found) {
                  return true;
                }
              }
            }
            return false;
          };

          findAndDeleteNode(newChart, event.tree_id);

          return newChart;
        });
        handleCloseConfirmOpen();
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  const handleSelectedChoice = (event) => {
    setSelectedItem(event.target.value);
  };

  const customNodeSvgShape = {
    shape: 'rect',
    shapeProps: {
      width: 0,
      height: 0,
      x: -20,
      y: 20,
      stroke: PRIMARY.main,
    },
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && choiceChart && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 1, py: 2 }}
          >
            <Box fontWeight="bold" fontSize="20px">{`Manage Choices - ${
              rowData?.current?.title || ''
            }`}</Box>
            <Box>
              <Link to={ROUTE_PATH.CONTENT_VIDEOS}>
                <Button color="primary" variant="contained">
                  Back
                </Button>
              </Link>
            </Box>
          </Stack>
          <Typography align="center" variant="h3" color="secondary">
            {rowData?.title}
          </Typography>
          <div
            id="treeWrapper"
            style={{
              width: '100%',
              height: '100vh',
              border: '1px solid #e3e3e3',
            }}
            ref={containerRef}
          >
            <Tree
              data={choiceChart}
              orientation="vertical"
              translate={translate}
              nodeSvgShape={customNodeSvgShape}
              separation={{ siblings: 2, nonSiblings: 2 }}
              allowForeignObjects
              onNodeClick={showChoicesChildNode}
              nodeLabelComponent={{
                render: <TreeNodeLabel onNodeClick={showChoicesChildNode} />,
                foreignObjectWrapper: {
                  width: 220,
                  height: 120,
                  y: -50,
                  x: -110,
                },
              }}
              pathFunc="step"
            />
          </div>
          {snackbarInfo.show && (
            <SnackbarInfo
              type={snackbarInfo.type}
              message={snackbarInfo.message}
            />
          )}
          {modalOpen && (
            <Dialog disableEscapeKeyDown open={modalOpen} onClose={handleClose}>
              <DialogTitle>Select option for choices</DialogTitle>
              <DialogContent>
                <Box
                  component="form"
                  sx={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  <FormControl sx={{ mx: 5, my: 2, minWidth: 250 }}>
                    <InputLabel id="demo-dialog-select-label">
                      Choice
                    </InputLabel>
                    <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={selectedItem}
                      onChange={handleSelectedChoice}
                      input={<OutlinedInput label="Choice" />}
                    >
                      {newChoices.length === 0 && (
                        <MenuItem disabled>No data found</MenuItem>
                      )}
                      {newChoices.length > 0 &&
                        newChoices.map((item) => (
                          <MenuItem value={item}>{item.title}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  component="form"
                  sx={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  <FormControl sx={{ mx: 5, my: 2, minWidth: 250 }}>
                    <TextField
                      label="Display Order"
                      value={displayOrder}
                      onChange={(e) => {
                        setDisplayOrder(e.target.value);
                      }}
                      type="number"
                      fullWidth
                      variant="filled"
                    />
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={clickToAddChoice}>Ok</Button>
              </DialogActions>
            </Dialog>
          )}
          {confirmModelOpen && (
            <ConfirmPopup
              title="Delete Choice"
              message="Do you want to delete this choice? This will delete all below choices."
              onCancel={handleCloseConfirmOpen}
              onSuccess={clickToDeleteChoice}
              successButtonText="Yes"
            />
          )}
        </>
      )}
    </>
  );
};

VideoChoices.propTypes = {
  dataId: PropTypes.number.isRequired,
};

export default VideoChoices;
