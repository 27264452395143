import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getCommentsByVideoId = async (params) => {
  const options = prepareRequestOptions(
    `/admin/videos/comments${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const checkNewCommentsByVideoId = async (videoId, commentId) => {
  const options = prepareRequestOptions(
    `/admin/videos/check-new-comments?videoId=${videoId}&commentId=${commentId}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const postCommentByVideoId = async (payload, params) => {
  const options = prepareRequestOptions(
    `/admin/videos/post-comment${params}`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateCommentStatusById = async (id, params) => {
  const options = prepareRequestOptions(
    `/admin/videos/comment/${id}/update-status${params}`,
    'POST',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
