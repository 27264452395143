import {
  LANDSCAPE_IMAGE_ASPECT_RATIO,
  LANDSCAPE_IMAGE_END_RANGE,
  LANDSCAPE_IMAGE_START_RANGE,
  PORTRAIT_IMAGE_ASPECT_RATIO,
  PORTRAIT_IMAGE_END_RANGE,
  PORTRAIT_IMAGE_START_RANGE,
} from '../config/const.config';

const validateSelectedVideoFile = (selectedFiles = null) => {
  let errorMsg = '';
  let i = 0;

  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select video file to upload.';
  }
  if (files?.length > 1) {
    errorMsg = 'Single video file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.mp4|\.mov|\.wmv|\.flv|\.avi|\.mkv|\.webm)$/i;
    for (; i < files?.length; i += 1) {
      if (!allowedExtensions.exec(files[i]?.name)) {
        errorMsg = 'Invalid file type selected.';
      }
    }
  }

  return errorMsg || null;
};

const validateSelectedImageFile = (selectedFiles = null) => {
  let errorMsg = '';
  let i = 0;

  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select image file to upload.';
  }
  if (files?.length > 1) {
    errorMsg = 'Single image file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    for (; i < files?.length; i += 1) {
      if (!allowedExtensions.exec(files[i]?.name)) {
        errorMsg = 'Invalid file type selected.';
      }
    }
  }

  return errorMsg || null;
};

const validateImageSize = (file, type) => {
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = () => {
      reject(new Error('Failed to load image.'));
    };

    img.onload = () => {
      const aspectRatio = Number((img.width / img.height).toFixed(2));
      if (
        (type === 'Landscape' &&
          aspectRatio >= LANDSCAPE_IMAGE_START_RANGE &&
          aspectRatio <= LANDSCAPE_IMAGE_END_RANGE) ||
        (type === 'Portrait' &&
          aspectRatio >= PORTRAIT_IMAGE_START_RANGE &&
          aspectRatio <= PORTRAIT_IMAGE_END_RANGE)
      ) {
        resolve({ error: false, width: img.width, height: img.height });
      } else {
        resolve({
          error: true,
          message: `Sorry, this image doesn't look like the size we wanted. We require image in ${
            type === 'Landscape'
              ? LANDSCAPE_IMAGE_ASPECT_RATIO
              : PORTRAIT_IMAGE_ASPECT_RATIO
          } aspect ratio.`,
          width: img.width,
          height: img.height,
        });
      }
      window.URL.revokeObjectURL(img.src);
    };

    try {
      img.src = window.URL.createObjectURL(file);
    } catch (error) {
      reject(new Error('Failed to create object URL.'));
    }
  });

  return promise;
};

export {
  validateSelectedVideoFile,
  validateSelectedImageFile,
  validateImageSize,
};
