import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSKUSettings from '../../components/sku/list-sku-settings.component';

const SKUSettingsView = () => {
  const pageTitle = 'Settings / SKU';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSKUSettings />
    </AppLayout>
  );
};

export default SKUSettingsView;
