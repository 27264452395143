import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import ManageCategoryVideos from '../../components/categories/manage-category-videos';

const ContentCategoriesManageVideos = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Categories';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ManageCategoryVideos id={id} />
    </AppLayout>
  );
};

export default ContentCategoriesManageVideos;
