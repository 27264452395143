// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__pnm_admin_t';
export const LS_ADMIN_USER_NAME = '__pnm_admin_n';
export const LS_ADMIN_USER_EMAIL = '__pnm_admin_e';
export const LS_ADMIN_USER_ROLE = '__pnm_admin_r';

export const VALID_ADMIN_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  CONTENT_OWNER: 'CONTENT_OWNER',
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
  13: 'Cancelled',
  14: 'Expired',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
  13: 'error',
  14: 'error',
};

export const STATUS_ALL_VALUE = 99999;

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  SHOWCHOICE: {
    value: 'show_choices',
    label: 'Show Choices',
  },
  MANAGE_VIDEOS: {
    value: 'manage_videos',
    label: 'Manage Videos',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CHANNELS: {
    value: 'assign_channels',
    label: 'Assign Channels',
  },
  UPLOAD_ASSETS: {
    value: 'upload_assets',
    label: 'Upload Assets',
  },
  GEO_RESTRICTION: {
    value: 'geo_restriction',
    label: 'Geo Restriction',
  },
};

export const VIDEO_TYPE = {
  1: 'Main',
  MAIN: 1,
  2: 'Choice',
  CHOICE: 2,
};

// Landscape ranges calculate based on 16:9 aspect ratio while portrait ranges calculate based on 2:3 aspect ratio
export const LANDSCAPE_IMAGE_ASPECT_RATIO = '16:9';
export const PORTRAIT_IMAGE_ASPECT_RATIO = '2:3';
export const LANDSCAPE_IMAGE_START_RANGE = 1.7;
export const LANDSCAPE_IMAGE_END_RANGE = 1.9;
export const PORTRAIT_IMAGE_START_RANGE = 0.6;
export const PORTRAIT_IMAGE_END_RANGE = 0.75;
