import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/categories/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getCategories = async () => {
  const options = prepareRequestOptions(
    '/admin/categories/category-list',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/categories/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const assignVideos = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/assign-video`,
    'POST',
    payload,
    true
  );
  const response = await axios(options);
  return response.data;
};

export const viewVideosById = async (id, params = '') => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/viewVideos${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteAssignedVideosById = async (id, videoId) => {
  const options = prepareRequestOptions(
    `/admin/categories/${id}/assigned-video/${videoId}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getListSequence = async (query) => {
  const options = prepareRequestOptions(
    `/admin/sequence/list/${query}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateCategoryVideosSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/video-category/update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
