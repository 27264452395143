// Define video watch session type
const WATCH_TYPE_LIST = [
  {
    id: 'AVOD',
    title: 'AVOD',
  },
  {
    id: 'TVOD',
    title: 'TVOD',
  },
];

export default WATCH_TYPE_LIST;
