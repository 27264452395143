import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Live users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'subscriberFullName',
    label: 'Subscriber',
    width: '15%',
    align: 'left',
    dataKey: 'subscriberFullName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriberUsername',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'video',
    label: 'Video',
    width: '20%',
    align: 'left',
    dataKey: 'video',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'countryName',
    label: 'Location / Device UID',
    width: '15%',
    align: 'left',
    dataKey: 'countryName',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'deviceId',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'monetizationType',
    label: 'Type',
    width: '10%',
    align: 'center',
    dataKey: 'monetizationType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    width: '15%',
    align: 'left',
    dataKey: 'sessionStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.DATETIME,
  },
  {
    id: 'totalSecondsWatch',
    label: 'Total Time Watched',
    width: '10%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];
