// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_CATEGORIES: '/content/categories',
  CONTENT_CATEGORIES_MANAGE_VIDEOS: '/content/categories/:id/manage-videos',
  CONTENT_CATEGORIES_MANAGE_VIDEO_ORDER:
    '/content/categories/:id/manage-videos/manage-video-order',
  CONTENT_VIDEOS: '/content/videos',
  CONTENT_VIDEOS_NEW: '/content/videos/new',
  CONTENT_VIDEOS_EDIT: '/content/videos/:id/edit',
  CONTENT_VIDEOS_CHOICES: '/content/videos/:id/choices',
  CONTENT_ADS: '/content/ads',
  CONTENT_COMMENTS: '/content/comments',
  SUBSCRIBERS: '/users/all',
  SKU: '/settings/sku',
  ADMIN_USERS: '/settings/access',
  SMTP_SETTINGS: '/settings/email',
  BILLING_PAYMENTS: '/users/purchases',
  REPORTS_OVERVIEW: '/reports/overview',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_SESSIONS: '/reports/video-sessions',
  REPORTS_APP_SESSIONS: '/reports/app-sessions',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;
