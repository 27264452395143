import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListCategories from '../../components/categories/list-categories.component';

const ContentCatgoriesView = () => {
  const pageTitle = 'Content / Categories';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListCategories />
    </AppLayout>
  );
};

export default ContentCatgoriesView;
